.camera {
  text-align: center;
  position: relative;
  width: 100vw;
  height: 100vh;
}
.trigger, .retake, .looksgood{
    width: 150px;
    background-color: black;
    color: white;
    font-size: 16px;
    border-radius: 30px;
    border: none;
    padding: 10px;
    text-align: center;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
    left: calc(50% - 75px);
    position: absolute;
    bottom: 100px;
}
.retake {
    right: calc(50% - -15px);
    left: auto;
}
.looksgood {
    left: calc(50% - -15px)
}
/*.buttons {
  text-align: center;
  padding: 10px 0;
}
.buttons button {
  min-width: 150px;
  background-color: black;
  color: white;
  font-size: 16px;
  border-radius: 30px;
  border: none;
  padding: 10px;
  text-align: center;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
  margin: 0 6px;
}*/