.overlay {
  position:fixed;
  display:block;
  width:100%;
  height:100%;
  top:0;
  left:0;
  background-color:rgba(74,74,74,.8);
  z-index:99999;
}
.loading {
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  width: 80px;
  background: #fff;
  padding:5px;
  line-height: 0;
}
/*Message*/
.success-container {
  position: relative;
  top: 0;
  height: 100vh;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}
.success-container p {
  color: #404F5E;
  font-weight: initial;
}
.info-msg, .success-msg, .warning-msg, .error-msg {
  padding: 10px;
  border-radius: 3px 3px 3px 3px;
  font-size: 18px;
  top: 50px;
  left: calc(50% - 42%);
  text-align: center;
}
.success-bg {
  background-color: #fdfffb;
}
.success-msg h1 {
  color: #270;
}
.error-bg {
  background-color: #fff8f9;
}
.error-msg h1 {
  color: #D8000C;
}
.info-bg {
  background-color: #f5fdff;
}
.info-msg h1 {
  color: #059;
}
.warning-bg {
  background-color: #fffef8;
}
.warning-msg h1 {
 color: #9F6000;
}
/*Message*/