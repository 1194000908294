/*Message*/
.failed-camera-container {
  position: relative;
  top: calc(50% - -105px);
  height: 100vh;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.failed-camera-container p {
  color: #404F5E;
  font-weight: initial;
}
.failed-camera-container .error-msg {
  font-size: 14px;
}
.failed-camera-container .error-msg h1 {
  font-size: 1.4em;
}
.failed-camera-container .error-msg .error-cross {
    font-size: 44px;
}
ol {
  padding-inline-start: 25px;
}
.info-msg, .success-msg, .warning-msg, .error-msg {
  padding: 10px;
  border-radius: 3px 3px 3px 3px;
  font-size: 18px;
  top: 50px;
  left: calc(50% - 42%);
  text-align: center;
}
.success-bg {
  background-color: #fdfffb;
}
.success-msg h1 {
  color: #270;
}
.error-bg {
  background-color: #fff8f9;
}
.error-msg h1 {
  color: #D8000C;
}
.info-bg {
  background-color: #f5fdff;
}
.info-msg h1 {
  color: #059;
}
.warning-bg {
  background-color: #fffef8;
}
.warning-msg h1 {
 color: #9F6000;
}
/*Message*/

/*.success-tick, .error-cross {
  line-height: 76px;
  width: 76px;
  height: 76px;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  font-family: emoji;
  margin: 0 auto;
}
.success-tick {
  background-color: #e4ffd9;
  font-size: 50px;
  color: #270;
}
.error-cross {
  background-color: #ffe5e6;
  font-size: 38px;
  color: #D8000C;
}*/