a {
  text-decoration: none;
  color: #505050;
  display: block;
}
span {
  font-size: 0.7em;
  display: block;
  /*color: #a5a5a5;*/
}
.home {
  margin-bottom: 90px;
}
/*.tick, .cross {
  line-height: 22px;
  width: 22px;
  height: 22px;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-family: emoji;
  position: absolute;
  right: 0;
  top: -5px;
}
.tick {
  background-color: #009688;
  font-size: 20px;
}
.cross {
  font-size: 22px;
  background-color: #F44336;
}*/
.right-arrow {
  position: absolute;
  right: 20px;
  font-size: 30px;
  top: 50%;
  height: 5px;
  line-height: 0;
}