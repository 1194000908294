body {
  /*background-color: #f6f7f9;*/
  background-color: #f3f1f1;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
h1 {
  font-weight: 600;
}
.font-weight-bold {
  font-weight: bold
}
.text-center {
  text-align: center
}
.text-left {
  text-align: left
}
.font-size-12 {
  font-size: 12px
}
.font-size-14 {
    font-size: 14px;
}
.font-size-16 {
    font-size: 16px;
}
.font-size-18 {
    font-size: 18px;
}
.m-0 {
  margin:0!important
}
.mb-0 {
  margin-bottom:0!important
}
.mt-0 {
  margin-top:0!important
}
.mt-25 {
  margin-top:25px !important
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

/* overlay */
.overlayTop, .overlayBottom {
  position: fixed;
  background-color: #3d3d3d;
  color: #fff;
  text-align: left;
  width: 100%;
  left: 0;
  right: 0;
}
/*.overlayTop span, .overlayBottom span {
  color: #fff;
}*/
.overlayTop {
  top: 0;
}
.overlayBottom {
  bottom: 0;
}
.overlayBox{
  padding: 10px 20px;
  font-size: 14px;
}
.overlayTop .overlayBox{
 font-size: 16px;
}
.overlayBottom .overlayBox{
 font-size: 14px;
}
/* overlay */

/* Camera Focus card image */
.focusImage {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  border: 0;
  left: 0;
  right: 0;
}
.focusImage img {
  width: 320px;
}
/* Camera Focus card image */

.rotate-your-device {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

/* buttons start */
.buttons {
  text-align: center;
  padding: 10px 0;
}
.buttons button {
  /*min-width: 150px;
  background-color: black;
  color: white;
  font-size: 16px;
  border-radius: 30px;
  border: none;
  padding: 10px;
  text-align: center;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
  margin: 0 6px;*/
  /*background-color: #0009e0;*/
  font-size: 16px;
  margin: 10px 6px 0 6px;
  color: #fff;
  letter-spacing: 1.04px;
  text-align: center;
  border-radius: 6px;
  border: 0;
  background-color: #000;
  padding: 12px 20px;
  text-transform: capitalize;
  cursor: pointer;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
}
button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
.buttons button.capture:disabled,
.buttons button.capture[disabled]{
  opacity: 0.3;
}

.buttons button.capture {
  font-size: 16px;
  /*margin: 0 6px;*/
  color: #000000;
  letter-spacing: 1.04px;
  text-align: center;
  border: 2px solid #ffffff;
  border-radius: 6px;
  background-color: #ffffff;
  text-transform: capitalize;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  border: 0.5em solid #ffffff;
  width: 36px;
  height: 36px;
  position: relative;
  padding: 0;
  text-indent: -10000px;
}
.buttons button.capture:before {
    content: " ";
    /* background: #fff; */
    width: 52px;
    height: 52px;
    display: block;
    border-radius: 100%;
    border: 5px solid #fff;
    margin: 0;
    padding: 0;
    position: absolute;
    top: -16px;
    left: -16px;
}
/* buttons end */

/*Success Tick and cross*/
.success-tick, .error-cross {
  line-height: 76px;
  width: 76px;
  height: 76px;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  font-family: emoji;
  margin: 0 auto;
}
.success-tick {
  background-color: #e4ffd9;
  font-size: 50px;
  color: #270;
}
.error-cross {
  background-color: #ffe5e6;
  font-size: 70px;
  color: #D8000C;
}
/*Success Tick and cross*/