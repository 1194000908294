html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
html {
  font-size: 11px;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-align: left;
  color: rgba(0,0,0,.88);
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
  /*overflow: hidden;*/ /* Disabling android's chrome pull-down-to-refresh feature */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, :after, :before {
    box-sizing: border-box;
}