.App {
  text-align: center;
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.App-text {
  color: #fff;
  /*background-color: rgba(4,24,164,.9);*/
  background-color: #0009e0;
  width: 100%;
  padding: 8px 0;
  margin-top: 10px;
}