.header {
  /*box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;*/
  background-color: #fff;
  border: 0 solid #e5e7eb;
  border-bottom-width: 1px;
  padding: 5px;
  text-align: center;
}

.headerLogo {
  max-width: 120px;
}