.card {
  /*box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgba(0,0,0,.1), 0 2px 4px -1px rgba(0,0,0,.06);*/
  box-shadow: 0 0 #0000, 0 0 #0000, 0 2px 3px -2px rgba(0,0,0,.1), 0 2px 4px -1px rgba(0,0,0,.06);
  border-radius: 0.5rem;
  background-color: #fff;
  padding: 20px;
  margin: 20px auto;
  /*font-size: 1.17em;
  font-weight: bold;
  overflow: hidden;*/
  position: relative;
  border-bottom: 2px solid #0418a4;
}