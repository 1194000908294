.camera {
  text-align: center;
  position: relative;
  width: 100vw;
  height: 100vh;
}
/*.camera:before {
  border: 1px dashed hsl(0, 0%, 66.7%);
  display: block;
  height: calc(100% - 20px);
  border-radius: 4px;
  content: " ";
  position: absolute;
  width: calc(100% - 20px);
  margin: 10px;
}*/
.trigger, .retake, .looksgood{
    width: 150px;
    background-color: black;
    color: white;
    font-size: 16px;
    border-radius: 30px;
    border: none;
    padding: 10px;
    text-align: center;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
    left: calc(50% - 75px);
    position: absolute;
    bottom: 50px;
}
.retake {
    right: calc(50% - -15px);
    left: auto;
}
.looksgood {
    left: calc(50% - -15px)
}

.main-container {
  padding: 5px;
}
.capture {
  position: absolute;
  right: 20px;
  font-size: 12px;
  top: 50%;
  text-transform: uppercase;
  color: #0418a4;
  font-weight: bold;
  letter-spacing: .05em;
  cursor: pointer;
}
.dec {
  color: #7D7D7D;
  margin-top: 5px;
}