.footer {
  background-color: #fff;
  padding: 10px 0;
  text-align: center;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.footer button {
  background: #f3500a;
  border: 0;
  color: #fff;
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: .08em;
  margin-top: 0;
}
.footer button[disabled] {
  background: #7d7d7d;
}