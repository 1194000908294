.camera {
  text-align: center;
}
.videoOtp {
  letter-spacing: 10px;
  text-align: center;
  padding: 10px;
  font-size: 20px;
}
/*.buttons {
  text-align: center;
  padding: 10px 0;
}
.buttons button {
  min-width: 150px;
  background-color: black;
  color: white;
  font-size: 16px;
  border-radius: 30px;
  border: none;
  padding: 10px;
  text-align: center;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
  margin: 0 6px;
}*/
.speakLouder img{
  float: left;
  margin: 6px 10px 6px 0;
}
.turnCardScreen {
  background: #333;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.turnCardScreen img {
  width: 250px;
}
.recorder-time {
  position: fixed;
  background-color: rgb(61, 61, 61, 0.7);
  color: #fff;
  text-align: left;
  /* width: 70px; */
  right: 20px;
  top: 80px;
  border-radius: 2px;
  padding: 4px 10px 4px 5px;
  letter-spacing: 1px;
}
.recorder-time .icon {
  background: #f00;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  display: inline-block;
  margin: 5px;
  float: left;
}