.camera {
  text-align: center;
}
/*.overlayTop, .overlayBottom {
  position: fixed;
  background-color: rgba(22,29,32,0.5);
  color: #fff;
  text-align: left;
  width: 100%;
}
.overlayTop span, .overlayBottom span {
  color: #fff;
}
.overlayTop {
  top: 0%;
}
.overlayBottom {
  bottom: 0%;
}
.overlayBox{
  padding: 10px 20px;
}*/
.videoOtp {
  letter-spacing: 10px;
  text-align: center;
  padding: 10px;
  font-size: 20px;
}
/*.buttons {
  text-align: center;
  padding: 10px 0;
}
.buttons button {
  min-width: 150px;
  background-color: black;
  color: white;
  font-size: 16px;
  border-radius: 30px;
  border: none;
  padding: 10px;
  text-align: center;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
  margin: 0 6px;
}*/
.speakLouder img{
  float: left;
  margin: 6px 10px 6px 0;
}
.focusFaceImage {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  border: 0;
  left: 0;
  right: 0;
}
.focusFaceImage img {
  width: 250px;
}