/*Message*/
.error-container {
  position: relative;
  top: calc(50% - -105px);
  height: 100vh;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}
.error-container p {
  color: #404F5E;
  font-weight: initial;
}
.error-container img {
  width: 76px;
  height: 76px;
}
.info-msg, .success-msg, .warning-msg, .error-msg {
  padding: 10px;
  border-radius: 3px 3px 3px 3px;
  font-size: 18px;
  top: 50px;
  left: calc(50% - 42%);
  text-align: center;
}
.success-bg {
  background-color: #fdfffb;
}
.success-msg h1 {
  color: #270;
}
.error-bg {
  background-color: #fff8f9;
}
.error-msg h1 {
  color: #D8000C;
}
.info-bg {
  background-color: #f5fdff;
}
.info-msg h1 {
  color: #059;
}
.warning-bg {
  background-color: #fffef8;
}
.warning-msg h1 {
 color: #9F6000;
}
/*Message*/